import React from "react";
import LessonLayout from "~/layouts/lesson";
import Introduction from "~/layouts/introduction";

import BSCSLogo from '~/images/lesson_3_step_2.jpg';



const Page = () => {
  return (
      <LessonLayout
        current_step={-2}
        lesson={"Lesson 3"}
        color={"hs"}
        total_count={8}
        module_title="Will You Buy What They Are Selling?"
      >
        <Introduction
          alt="tablet"
          image={BSCSLogo}
          description="Buy this! Give to this cause! Companies and organizations want to get your attention—and sometimes your money. Should you believe what the ads say?  In this lesson, you will use critical thinking skills to analyze ads to see how ads try to influence people’s decisions."
        />

      </LessonLayout>
  )
}

export default Page;
